const authConfig = {
    authority: 'https://login.infolab.uz',
    client_id: '251494582150299728@data-collector',
    redirect_uri: 'https://s-mart.crm.select-zaim.uz',
    response_type: 'code',
    scope: 'openid profile email urn:zitadel:iam:org:project:id:data-collector:aud',
    post_logout_redirect_uri: 'https://s-mart.crm.select-zaim.uz',
    response_mode: 'query',
    code_challenge_method: 'S256',
};

export default authConfig;
